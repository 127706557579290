import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  Fragment,
} from 'react';
import {
  Icon,
  VerificationsChart,
  ActiveUsersChart,
  MessagesChart,
  PassengerDistancesChart,
  RegistrationsChart,
  WalkingChart,
  CyclingChart,
  Co2Chart,
  NoxChart,
  CommunityElement,
  FiltersModal,
  VerifiedJourneysChart,
} from 'components';
import {
  ReportContainer,
  useCallbackRef,
  DatePicker,
  useQueryParams,
  MiniReportTotal,
  Menu,
  ButtonProps,
  useUtilities,
  getColor,
  Heading,
  useModalUtilities,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import classNames from 'classnames';

import config from 'config';
import { UserContext, ReportsContext } from 'store';
import { INameExtended } from 'components/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import { ChartData } from 'models';
import { useHeadTitle } from 'hooks';
import { LeaderboardType } from 'modules';
import { reportsConfig } from 'config/reportsConfig';
import { JOURNEY_DIRECTIONS, capitalizeFirstLetter } from 'utils';
import { ActiveUsersChartRef } from 'components/_charts/ActiveUsersChart/ActiveUsersChart.component';
import Leaderboard from 'components/_organisms/Leaderboard';
import {
  Filter,
  RadioFilter,
} from 'components/_modals/FiltersModal/FiltersModal.component';
import { PageLayout } from 'components/_layouts';
import ReportsPDF from './ReportsPDF';

const Reports = (): JSX.Element => {
  const {
    community,
    communityId,
    userPreferences: { unit, dateFormat },
    includeTestUsers,
  } = useContext(UserContext);

  const {
    dateRange,
    kpiData,
    rangeStats,
    loadingDistances,
    depotFilters,
    activeNumberOfPoints,
    setActiveLeaderBoardType,
    convertReportsDataToArrays,
    numberOfMessagesTotal,
    numberOfTestMessagesTotal,
    updateDirections,
  } = useContext(ReportsContext);

  const { showOverlay, hideOverlay, showSnackBar, hideSnackBar } =
    useUtilities();

  const { params, setMultipleQueryParams } = useQueryParams<{
    from: string;
    to: string;
    directions: string;
    filterId: string;
  }>();

  const { t } = useTranslation();

  useHeadTitle(t('header_reports'));

  const navigate = useNavigate();

  const { hash } = useLocation();

  const filterBtnRef = useRef<HTMLButtonElement>(null);

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const [numberOfPointsLeaderboard, setNumberOfPointsLeaderboard] =
    useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  const pageRef = useRef<HTMLDivElement>(null);
  const exportRef = useRef<HTMLButtonElement>();

  const {
    open,
    openModal: openPdfModal,
    closeModal: closePdfModal,
  } = useModalUtilities();

  const [activeUsersChart, activeUsersChartRef] =
    useCallbackRef<ActiveUsersChartRef>();

  const calendarLabels = useMemo(
    () => ({
      arrowLeftDay: t('accessibility-arrow_left_day'),
      arrowRightDay: t('accessibility-arrow_right_day'),
      arrowLeftMonth: t('accessibility-arrow_left_month'),
      arrowRightMonth: t('accessibility-arrow_right_month'),
      arrowLeftYear: t('accessibility-arrow_left_year'),
      arrowRightYear: t('accessibility-arrow_right_year'),
      closeButton: t('accessibility-calendar_close-button'),
    }),
    [t]
  );

  const registrationsTotal = useMemo(
    () =>
      rangeStats
        ? Object.values(rangeStats.registeredUsers.chartData)
            .flatMap((el) => +el)
            .reduce((a, b) => a + b, 0)
        : 0,
    [rangeStats]
  );

  const registrationsTestTotal = useMemo(
    () =>
      rangeStats
        ? includeTestUsers && rangeStats.registeredUsers.testChartData
          ? Object.values(rangeStats.registeredUsers.testChartData as ChartData)
              .flatMap((el) => +el)
              .reduce((a, b) => a + b, 0)
          : 0
        : 0,
    [includeTestUsers, rangeStats]
  );

  const roadTotal = useMemo(
    () =>
      +(
        Number(kpiData?.walkingTotal) +
        Number(kpiData?.cyclingTotal) +
        Number(kpiData?.passengerDistancesTotal)
      ).toFixed(2),
    [kpiData]
  );

  const roadTotalTest = useMemo(
    () =>
      +(
        Number(kpiData?.walkingTotalTest) +
        Number(kpiData?.cyclingTotalTest) +
        Number(kpiData?.passengerDistancesTotalTest)
      ).toFixed(2),
    [kpiData]
  );

  const filterValues = useMemo(
    () => ({
      directions: params.directions?.split(',').filter(Boolean),
      filterId: params.filterId,
    }),
    [params]
  );

  const activeFiltersCount = useMemo(
    () =>
      Object.values(filterValues).reduce((acc, value) => {
        if (Array.isArray(value)) return (acc += value.length);
        else if (typeof value === 'string') return (acc += 1);
        else return acc;
      }, 0),
    [filterValues]
  );

  const radioFilters = useMemo<RadioFilter[]>(
    () =>
      depotFilters
        ? [
            {
              legendLabel: t('global-leaderboards'),
              name: 'filterId',
              options: depotFilters?.map((filter) => ({
                label: filter.data.name,
                value: `${filter.id}`,
              })),
            },
          ]
        : [],
    [depotFilters, t]
  );

  const filters = useMemo<Filter[]>(
    () => [
      {
        legendLabel: t('mJourneys'),
        name: 'directions',
        checkboxes: [
          {
            value: 'to-office',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-office'])),
            id: 'filter_reports_to-office',
          },
          {
            value: 'to-home',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-home'])),
            id: 'filter_reports_to-home',
          },
          {
            value: 'to-event',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-event'])),
            id: 'filter_reports_to-event',
          },
          {
            value: 'to-custom',
            label: capitalizeFirstLetter(t(JOURNEY_DIRECTIONS['to-custom'])),
            id: 'filter_reports_to-custom',
          },
        ],
      },
    ],
    [t]
  );

  const stringToArrayBuffer = useCallback((s: string) => {
    const buffer = new ArrayBuffer(s.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buffer;
  }, []);

  const generateXLSX = useCallback(async () => {
    if (!communityId) {
      return;
    }

    showOverlay('body', 'fixed');

    const id = showSnackBar(
      {
        text: t('waiting_download'),
        variant: 'loading',
        loadingText: t('downloading'),
      },
      { constant: true }
    );

    const data = await convertReportsDataToArrays(numberOfPointsLeaderboard);
    if (!data) {
      return;
    }

    const book = XLSX.utils.book_new();

    const sheetName = t('header_reports');

    book.Props = {
      Title: t('header_reports'),
      Author: 'Kinto',
      CreatedDate: new Date(),
    };

    book.SheetNames.push(sheetName);

    const sheet = XLSX.utils.aoa_to_sheet(data);
    const wscols = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    sheet['!cols'] = wscols;

    book.Sheets[sheetName] = sheet;

    const bookout = XLSX.write(book, { bookType: 'xlsx', type: 'binary' });

    saveAs(
      new Blob([stringToArrayBuffer(bookout)], {
        type: 'application/octet-stream',
      }),
      `report_${dayjs().format(dateFormat)}.xlsx`
    );

    hideSnackBar(id);
    hideOverlay('body');
  }, [
    communityId,
    convertReportsDataToArrays,
    dateFormat,
    hideOverlay,
    hideSnackBar,
    numberOfPointsLeaderboard,
    showOverlay,
    showSnackBar,
    stringToArrayBuffer,
    t,
  ]);

  const scrollToAnchor = useCallback(() => {
    if (['#main', '#footer'].includes(hash)) return;

    if (hash === '') {
      // if not a hash link scroll to top
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      const id = hash.replace('#', '');

      const locateAndScroll = () => {
        // TODO: consider creating a map of possible scroll-to elements
        const element = document.getElementById(id);

        if (!element) {
          return;
        } else {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });

          clearInterval(interval);
        }
      };

      const interval = setInterval(locateAndScroll, 500);
    }
  }, [hash]);

  const onSubmit = useCallback(
    async ({ directions, filterId }: any) => {
      const filter = depotFilters?.find((filter) => filter.id === +filterId);

      const filterRange = {
        to: dayjs(filter?.data.to).format(config.apiDateFormat),
        from: dayjs(filter?.data.from).format(config.apiDateFormat),
      };

      setFiltersModalOpen(false);
      setActiveLeaderBoardType(
        !filter ? 'point' : (`${filter.id}` as LeaderboardType)
      );

      setMultipleQueryParams({
        filterId: filterId === undefined ? null : filterId,
        directions,
        ...(filter && {
          ...filterRange,
        }),
        ...(!filterId && {
          from: '',
          to: '',
        }),
      });
    },
    [depotFilters, setActiveLeaderBoardType, setMultipleQueryParams]
  );

  const exportMenuItems = useMemo(
    () =>
      [
        {
          id: 'export_reports_pdf',
          children: t('global-button_view_pdf'),
          icon: <Icon name="file-pdf" />,
          onClick: () => openPdfModal(),
        },
        {
          id: 'export_reports_xlsx',
          children: t('global-download_value', { value: 'XLSX' }),
          icon: <Icon name="file-spreadsheet" />,
          onClick: () => generateXLSX(),
        },
      ] as ButtonProps[],
    [generateXLSX, openPdfModal, t]
  );

  const handleScrollToAnchor = useCallback(
    (anchor: string) => {
      navigate({ hash: anchor });
      scrollToAnchor();
    },
    [navigate, scrollToAnchor]
  );

  useEffect(() => {
    if (loadingDistances) return;

    scrollToAnchor();
  }, [loadingDistances, scrollToAnchor]);

  useEffect(() => {
    updateDirections(filterValues.directions?.join(','));
  }, [filterValues, updateDirections]);

  const totalReportsData = useMemo(
    () => (
      <div className="total-reports-data">
        <MiniReportTotal<INameExtended>
          loading={loadingDistances || !community}
          iconName="users"
          total={`${community?.approved_users || 0}`}
          hasTest={includeTestUsers}
          totalTest={`${community?.['approved_users-test']}`}
          text={t('approved_community_members')}
        />
        <MiniReportTotal<INameExtended>
          loading={loadingDistances}
          iconName="leaf"
          total={`${kpiData?.co2Total || 0}`}
          hasTest={includeTestUsers}
          totalTest={`${kpiData?.co2TotalTest || 0}`}
          text={parse(t('total_co2_savings_kg_unit'))}
          type="green"
          buttonId="navigate_to_environmental_co2_report"
          goToButtonAriaLabel={t('accessibility-button_go_to_destination', {
            destination: 'CO₂',
          })}
          onClick={() => {
            handleScrollToAnchor('co2-chart');
          }}
        />
        <MiniReportTotal<INameExtended>
          loading={loadingDistances}
          iconName="clouds"
          total={`${kpiData?.noxTotal || 0}`}
          hasTest={includeTestUsers}
          totalTest={`${kpiData?.noxTotalTest || 0}`}
          text={parse(t('total_nox_savings_g'))}
          buttonId="navigate_to_environmental_nox_report"
          goToButtonAriaLabel={t('accessibility-button_go_to_destination', {
            destination: 'NOₓ',
          })}
          onClick={() => {
            handleScrollToAnchor('nox-chart');
          }}
        />
        <MiniReportTotal<INameExtended>
          loading={loadingDistances}
          iconName="road"
          total={`${roadTotal || 0}`}
          hasTest={includeTestUsers}
          totalTest={`${roadTotalTest || 0}`}
          text={t('total_unit_saved')}
          buttonId="navigate_to_walking_cycling_report"
          goToButtonAriaLabel={t('accessibility-button_go_to_destination', {
            destination: t('total_unit_saved').toLowerCase(),
          })}
          onClick={() => {
            handleScrollToAnchor('walking-chart');
          }}
        />
      </div>
    ),
    [
      community,
      handleScrollToAnchor,
      includeTestUsers,
      kpiData,
      loadingDistances,
      roadTotal,
      roadTotalTest,
      t,
    ]
  );

  const activeUsersChartMemo = useMemo(
    () => (
      <ReportContainer
        className="kinto-page__body__full"
        title={t('active_users')}
        info={t('reports-short_info_active_users')}
        tooltipContent={parse(
          t('active_users_graph').replace(/\\n/g, '<br />')
        )}
        totals={[
          {
            number: activeUsersChart?.activeUsersTotal.total,
            testNumber: activeUsersChart?.activeUsersTotal.testTotal,

            hasTestData: includeTestUsers,
          },
        ]}
      >
        <ActiveUsersChart ref={activeUsersChartRef} />
      </ReportContainer>
    ),
    [activeUsersChart, activeUsersChartRef, includeTestUsers, t]
  );

  const registrationsChartMemo = useMemo(
    () => (
      <ReportContainer
        title={t('registrations')}
        totals={[
          {
            number: registrationsTotal,
            testNumber: registrationsTestTotal,
            hasTestData: includeTestUsers,
          },
        ]}
        info={t('reports-short_info_registration')}
        tooltipContent={parse(
          t('registration_graph').replace(/\\n/g, '<br />')
        )}
      >
        <RegistrationsChart />
      </ReportContainer>
    ),
    [includeTestUsers, registrationsTestTotal, registrationsTotal, t]
  );

  const numberOfMessagesChartMemo = useMemo(
    () => (
      <ReportContainer
        title={t('number_of_messages')}
        tooltipContent={parse(t('messages_graph').replace(/\\n/g, '<br />'))}
        totals={[
          {
            number: numberOfMessagesTotal,
            testNumber: numberOfTestMessagesTotal,
            hasTestData: includeTestUsers,
          },
        ]}
        info={t('reports-short_info_number_of_messages')}
      >
        <MessagesChart />
      </ReportContainer>
    ),
    [includeTestUsers, numberOfMessagesTotal, numberOfTestMessagesTotal, t]
  );

  const leaderboardMemo = useCallback(
    (selectState?: boolean) => (
      <ReportContainer
        className="kinto-page__body__full leaderboard-container"
        title={t('leaderboards')}
        info={t('reports-short_info_leaderboard')}
        tooltipContent={parse(t('leaderboard_text').replace(/\\n/g, '<br />'))}
      >
        <Leaderboard
          directions={filterValues.directions?.join(',')}
          selectState={selectState}
          onActivatePoints={(value) => setNumberOfPointsLeaderboard(value)}
        />
      </ReportContainer>
    ),
    [filterValues, t]
  );

  const walkingChartMemo = useMemo(
    () => (
      <ReportContainer
        title={t('cp_map_walking')}
        totals={[
          {
            number: Number(kpiData?.walkingTotal),
            testNumber: Number(kpiData?.walkingTotalTest),
            unit,
            hasTestData: includeTestUsers,
          },
        ]}
        info={t('reports-short_info_walking')}
        tooltipContent={parse(t('walking_graph').replace(/\\n/g, '<br />'))}
      >
        <WalkingChart />
      </ReportContainer>
    ),
    [includeTestUsers, kpiData, t, unit]
  );

  const cyclingChartMemo = useMemo(
    () => (
      <ReportContainer
        title={t('cp_map_cycling')}
        totals={[
          {
            number: Number(kpiData?.cyclingTotal),
            testNumber: Number(kpiData?.cyclingTotalTest),
            unit,
            hasTestData: includeTestUsers,
          },
        ]}
        info={t('reports-short_info_cycling')}
        tooltipContent={parse(t('cycling_graph').replace(/\\n/g, '<br />'))}
      >
        <CyclingChart />
      </ReportContainer>
    ),
    [includeTestUsers, kpiData, t, unit]
  );

  const verificationsChartMemo = useMemo(
    () => (
      <ReportContainer
        title={t('reports-chart_title_verifications')}
        totals={[
          {
            number: Number(kpiData?.verificationsTotal),
            testNumber: Number(kpiData?.verificationsTotalTest),
            hasTestData: includeTestUsers,
          },
        ]}
        info={t('reports-chart_short_description_verifications')}
        tooltipContent={parse(
          t('reports-chart_long_description_verifications').replace(
            /\\n/g,
            '<br />'
          )
        )}
      >
        <VerificationsChart />
      </ReportContainer>
    ),
    [includeTestUsers, kpiData, t]
  );

  const verifiedJourneysChartMemo = useMemo(
    () => (
      <ReportContainer
        title={t('verified_journeys')}
        totals={[
          {
            number: Number(kpiData?.verifiedJourneysTotal),
            testNumber: Number(kpiData?.verifiedJourneysTotalTest),
            hasTestData: includeTestUsers,
          },
        ]}
        info={t('reports-short_info_verified_journeys')}
        tooltipContent={parse(t('journeys_graph').replace(/\\n/g, '<br />'))}
      >
        <VerifiedJourneysChart />
      </ReportContainer>
    ),
    [includeTestUsers, kpiData, t]
  );

  const passengerDistancesChartMemo = useMemo(
    () => (
      <ReportContainer
        title={t('passenger_distances')}
        tooltipContent={parse(t('distance_graph').replace(/\\n/g, '<br />'))}
        totals={[
          {
            number: Number(kpiData?.passengerDistancesTotal),
            testNumber: Number(kpiData?.passengerDistancesTotalTest),
            unit,
            hasTestData: includeTestUsers,
          },
        ]}
        info={t('reports-short_info_passenger_distance')}
      >
        <PassengerDistancesChart />
      </ReportContainer>
    ),
    [includeTestUsers, kpiData, t, unit]
  );

  const environmentalCo2ChartMemo = useMemo(
    () => (
      <ReportContainer
        title="CO₂"
        tooltipContent={parse(t('co2_graph').replace(/\\n/g, '<br />'))}
        totals={[
          {
            number: Number(kpiData?.co2Total),
            testNumber: Number(kpiData?.co2TotalTest),
            unit: 'kg',
            hasTestData: includeTestUsers,
          },
        ]}
        info={parse(t('reports-short_info_co2'))}
      >
        <Co2Chart />
      </ReportContainer>
    ),
    [includeTestUsers, kpiData, t]
  );

  const environmentalNoxChartMemo = useMemo(
    () => (
      <ReportContainer
        title="NOₓ"
        tooltipContent={parse(t('nox_graph').replace(/\\n/g, '<br />'))}
        totals={[
          {
            number: Number(kpiData?.noxTotal),
            testNumber: Number(kpiData?.noxTotalTest),
            unit: 'g',
            hasTestData: includeTestUsers,
          },
        ]}
        info={parse(t('reports-short_info_nox'))}
      >
        <NoxChart />
      </ReportContainer>
    ),
    [includeTestUsers, kpiData, t]
  );

  useEffect(() => {
    const mainContainer = document.getElementById('main-container');

    // we want to detect heading element height
    // because heading is only element aboove header we want to make sticky
    // after user scroll height of heading header must become sticky positioned
    const headingElement: HTMLHeadingElement = document.getElementsByClassName(
      'kinto-page__heading'
    )[0] as HTMLHeadingElement;

    if (headingElement) {
      const headerStyle = getComputedStyle(headingElement);

      const headerHeight =
        headingElement.offsetHeight +
        parseInt(headerStyle.marginTop) +
        parseInt(headerStyle.marginBottom);

      const scrollListenerFn = () => {
        setIsScrolled(mainContainer?.scrollTop! > headerHeight);
      };

      mainContainer?.addEventListener('scroll', scrollListenerFn, true);

      return () => {
        mainContainer?.removeEventListener('scroll', scrollListenerFn);
      };
    }
  }, []);

  return (
    <PageLayout
      className={classNames('kinto-page', 'reports', {
        'reports--is-scrolled': isScrolled,
      })}
      ref={pageRef}
    >
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('header_reports')}
      </Heading>

      <div
        className={classNames(
          'kinto-page__header',
          'kinto-page__header--row',
          'kinto-page__header__reports-page'
        )}
      >
        <CommunityElement />

        <div className="kinto-page__header__commands">
          {/* DATE RANGE SELECTOR */}
          <DatePicker
            value={dateRange}
            calendarAriaLabels={calendarLabels}
            startingRangeDate={reportsConfig.range_start}
            dateFormat={dateFormat}
            openPosition="bottom-left"
            className="kinto-page__header__date-picker"
            formButtonsLabels={{
              submitLabel: t('apply'),
              cancelLabel: t('cancel'),
            }}
            buttonsLabels={{
              customDate: t('custom_date'),
              last30Days: t('last_value_days', { value: 30 }),
              last7Days: t('last_value_days', { value: 7 }),
              last3Days: t('last_value_days', { value: 3 }),
            }}
            onChange={(v) => {
              setMultipleQueryParams({
                from: v.from.format(config.apiDateFormat),
                to: v.to.format(config.apiDateFormat),
                filterId: null,
              });

              setActiveLeaderBoardType(activeNumberOfPoints ? 'point' : 'all');
            }}
          />

          {/* FILTERS */}
          <button
            ref={filterBtnRef}
            className={classNames('kinto-filter-button', {
              'kinto-filter-button--filters-active': activeFiltersCount > 0,
            })}
            onClick={() => setFiltersModalOpen(true)}
          >
            <Icon name="filter" />
            <span>{t('filters')}</span>
            {activeFiltersCount > 0 && <span>{activeFiltersCount}</span>}
          </button>

          {/* GENERATE REPORT */}
          <Menu
            hasCloudArrow
            className="kinto-page__header__commands__generate-reports"
            id="export_reports_dropdown"
            portalClassName="generate-reports-menu"
            menuItems={exportMenuItems}
            triggerTitle={t('export')}
            openPosition="bottom-left"
            triggerProps={{
              variant: 'ghost',
              icon: <Icon name="chevron-down" className="wcl-icon--rotate" />,
              iconPosition: 'right',
              onKeyDown: (e) => {
                exportRef.current = e.target as HTMLButtonElement;
              },
            }}
          />
        </div>
      </div>

      {/* TOTALS */}
      {totalReportsData}

      <div className="kinto-page__body">
        {/* ACTIVE USERS */}
        {activeUsersChartMemo}

        {/* REGISTRATIONS */}
        {registrationsChartMemo}

        {/* NUMBER OF MESSAGES */}
        {numberOfMessagesChartMemo}

        {/* LEADERBOARD */}
        {leaderboardMemo()}

        {/* WALKING */}
        {walkingChartMemo}

        {/* CYCLING */}
        {cyclingChartMemo}

        {/* JOURNEYS */}
        {verificationsChartMemo}

        {/* VERIFIED JOURNEYS */}
        {verifiedJourneysChartMemo}

        {/* PASSENGER DISTANCES */}
        {passengerDistancesChartMemo}

        {/* ENVIRONMENTAL CO2 */}
        {environmentalCo2ChartMemo}

        {/* ENVIRONMENTAL NOX */}
        {environmentalNoxChartMemo}
      </div>

      {filtersModalOpen && (
        <FiltersModal
          onClose={() => setFiltersModalOpen(false)}
          filters={filters}
          radioFilters={radioFilters}
          onSubmit={onSubmit}
          triggerRef={filterBtnRef.current!}
          initialData={filterValues}
        />
      )}

      {open && (
        <ReportsPDF
          triggerRef={exportRef.current}
          onClose={() => {
            closePdfModal();
            exportRef.current?.focus();
          }}
          pages={[
            <Fragment>
              {totalReportsData}
              {activeUsersChartMemo}
            </Fragment>,
            registrationsChartMemo,
            numberOfMessagesChartMemo,
            leaderboardMemo(true),
            walkingChartMemo,
            cyclingChartMemo,
            verificationsChartMemo,
            verifiedJourneysChartMemo,
            passengerDistancesChartMemo,
            environmentalCo2ChartMemo,
            environmentalNoxChartMemo,
          ]}
        />
      )}
    </PageLayout>
  );
};

export default Reports;
